import React from 'react'
import Layout from '../components/layout'
import notesLayout from '../components/notes.module.scss'
import Head from '../components/head'
import { graphql, useStaticQuery } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'


export const query = graphql`
    query{
      allContentfulNotes(
        sort: {
          fields: publishedDate,
          order: DESC
        }
      ){
        edges{
        node{
          title
          publishedDate(formatString: "MMMM DD YYYY")
          type
          tags
          body{
            json
          }
        }
      }
    }
}
`

const NotePage = (props) => {
    const notes = props.data.allContentfulNotes.edges;

    
    const options = {
        renderNode:{
            "embedded-asset-block": (node) =>{
                const alt = node.data.target.fields.title['en-US']
                const url = node.data.target.fields.file['en-US'].url
                return <img alt={alt} src={url} width={200} />
            }
        }
       }

    
    return(
        <Layout>
          <div className="mainContentWidth">
            <Head title="Notes" />
            <div className={notesLayout.headerSection}>
            <h2> Notes </h2>
              <p>A space for sporadic and myriad notes. These are taken from my writings and include quotations and other references that are related to the subject matter at hand. They may well refer to blog posts that extrapolate on the content, in which case a link will usually be made. Some day I'll get the tagging system into place and then these will be searchable and sortable.</p>
            </div>
            <ol className= {notesLayout.gridSimple01}>
                {
                notes.map(( { node }) => {
                    const title = node.title;
                    const date = node.publishedDate;
                    {/*
                      const type = node.type;
                      const tags = node.tags;
                    */}
                    return(   
                        <div className={notesLayout.noteItem}>
                            <h2> { title } </h2>
                            <p className={notesLayout.noteDate}> { date } </p>
                            {/* 
                                <p className={notesLayout.noteDate}> { type } </p>
                                <p> tags: { tags.join(', ') }</p>
                                <p className={notesLayout.noteTag}> { tags } </p>
                          */}
                             <div className={notesLayout.noteBody} >  { documentToReactComponents(node.body.json, options)}</div>
                        </div>   
                        
                    )
                })
            }
            </ol>
            </div>
        </Layout>
    )
}

export default NotePage